import React,{useEffect,useRef,useState} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import "./AdminOrder.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setProducts,setOrderCompleteProduct,setOrderPendingProduct } from '../../redux/actions/productActions';
import http from '../../http';
import { Link } from "react-router-dom";
// import env from "react-dotenv";
var complete_id = 0;
var retrieve_id = 0;
var remove_id = 0;
var btn_remove_index = 0;
var newquan = 0;
var min = 0;
var max = 10;
var count = 0;
var minComplete = 0;
var maxComplete = 10;
var countComplete = 0;
function AdminOrder() {
  
    const allRemove = useRef([]);
    const [newQuantity, setNewQuantity] = useState(0);
    const [isComplete, setIsComplete] = useState(0);
    var myHeaders = new Headers();
    const dispatch = useDispatch();
    myHeaders.append("Content-Type", "application/json");
    const products = useSelector((state) => state.orderAllPendingProducts.products);
    const Completeproducts = useSelector((state) => state.orderAllCompleteProducts.products);
   
   
    const markComplete = (id) => {
        complete_id = id;
        setIsComplete(1);
        const newComplete = [...products];
        console.log(newComplete);

        http.get(`/admin-order/${id}`).then((result) => {

            const getObj = newComplete.filter((product, index)=>product.id==complete_id);
            const getIndex = newComplete.findIndex((product) => product.id == getObj[0].id);
            setIsComplete(0);
            if (getIndex !== -1) {
                const completedOrder = [...Completeproducts];
                completedOrder.push(getObj[0]);
                dispatch(setOrderCompleteProduct(completedOrder));
    
                const pendingProduct = [...products];
                pendingProduct.splice(getIndex, 1);
                dispatch(setOrderPendingProduct(pendingProduct));
            }
            notifySuccess(result.data.message);
        })
        .catch(error => { 
            setIsComplete(0);
                    notifyError(error.message);
        });
   
   }
   
   const notifySuccess = (result) => { 
    toast.success(`${result}`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
}
const notifyError=(result)=>{
   toast.error(`${result}`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
}
    const setNext = () => {
        min = max;
        max = max + 10;
        const newProd = [...products];
        dispatch(setOrderPendingProduct([]));
        dispatch(setOrderPendingProduct(newProd));
        count = min;
    }
    const setNextComplete = () => {
        minComplete = maxComplete;
        maxComplete = maxComplete + 10;
        const newProd = [...Completeproducts];
        dispatch(setOrderCompleteProduct([]));
        dispatch(setOrderCompleteProduct(newProd));
        count = minComplete;
    }
    const setPrevious = () => {
        max = min;
        min = min - 10;
        const newProd = [...products];
        dispatch(setOrderPendingProduct([]));
        dispatch(setOrderPendingProduct(newProd));
        count = min;
    }
    const setPreviousComplete = () => {
        maxComplete = minComplete;
        minComplete = minComplete - 10;
        const newProd = [...Completeproducts];
        dispatch(setOrderCompleteProduct([]));
        dispatch(setOrderCompleteProduct(newProd));
        count = minComplete;
    }
    return (
        <>
            <div className="row mt-3">
                <div className="col-12">
                    <div className="row">
                    <div className="col-6">
                        <h1 className="h2">Order List</h1> 
                    </div>
                   
                    
                    <div className="col-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">List of Pending Orders</h6>
                            </div>
                            <div className="card-body">
                                    <div className="table-responsive">
                                    <table className="table table-bordered"  width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                        <th className='five'>#</th>
                                        <th className='five' >Order Id</th>
                                        <th className='fifteen'>Customer Name</th>
                                        <th className='forty'>Address</th>
                                        <th className='fifteen'>Contact Number</th>
                                        <th className='five'>Date Ordered</th>
                                        <th  className='fifteen'>Action</th>
                                        </tr>
                                            </thead>
                                            <tbody>
                                            {
                                            products.length>0 ?   
                                                products.filter((product,index)=> index>=min && index<max).
                                                map((product,index) => {
                                                return (

                                                    <tr key={index+1}>
                                                        <th>{ count+index+1}</th>
                                                        <td>
                                                           {product.id}        
                                                        </td>
                                                        <td>{`${product.first_name} ${product.middle_initial} ${product.last_name}`}</td>
                                                        <td>{`${product.address_line_1} ${product.barangay} ${product.city} ${product.province}`}</td>
                                                        <td>{product.mobile_number}</td>
                                                        <td>{product.date_order}</td>
                                                        <td className="text-center">
                                                        {
                                                            isComplete===0 ?
                                                            <button onClick={()=>markComplete(product.id)}  className="btn btn-primary me-xxl-1 me-xl-1 me-lg-1 me-md-0 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-1 mb-sm-1 mb-1">
                                                                <i className="fa-solid fa-check"></i>
                                                            </button>
                                                            :
                                                            complete_id==product.id ?
                                                            <button disabled onClick={()=>markComplete(product.id)}  className="btn btn-primary me-xxl-1 me-xl-1 me-lg-1 me-md-0 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-1 mb-sm-1 mb-1">
                                                               <div className="spinner-border spinner-border-sm" role="status">
                                                                        <span className="visually-hidden">Loading...</span>
                                                                        </div>  
                                                            </button>
                                                            :
                                                            <button disabled onClick={()=>markComplete(product.id)}  className="btn btn-primary me-xxl-1 me-xl-1 me-lg-1 me-md-0 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-1 mb-sm-1 mb-1">
                                                            <i className="fa-solid fa-check"></i>
                                                        </button>
                                                        }
                                                            <Link to={`/order-pdf/${product.id}` } className="btn btn-info">
                                                            <i className="fa-solid fa-print"></i>
                                                        </Link>   
                                                           
                                                          
                                        
                                                           
                                                        </td>
                                                       
                                                    </tr>
                                                   
                                                )
                                            }) 
                                            :
                                            <tr>
                                                <td colSpan="9" className="text-center">
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span className="visually-hidden">Loading...</span>
                                                <span className="ms-2">Preparing  list of products. . .</span>
                                                </td>
                                            </tr>            
                                            }
                                                
                                            </tbody>
                                            </table>
                                </div>
                                <div className="text-end">
                                <div className="btn-group" role="group" aria-label="Basic outlined example">
                                 {
                                    min>0 ?
                                    <button type="button" 
                                    className="btn btn-outline-primary" 
                                    onClick={()=>setPrevious()}>Previous</button>
                                    :
                                    <button disabled type="button" 
                                    className="btn btn-outline-primary" 
                                    onClick={()=>setPrevious()}>Previous</button>
                                 }   
                                {
                                    max>=products.length ?
                                    <button disabled type="button" 
                                        className="btn btn-outline-primary"
                                        onClick={()=>setNext()}>Next</button>
                                    :
                                    <button type="button" 
                                        className="btn btn-outline-primary"
                                        onClick={()=>setNext()}>Next</button>
                                }
                                
                                </div> 
                                </div>
                            </div>
                        </div>
                           
                        </div>
                        
                        <div className="col-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">List of Complete Orders</h6>
                            </div>
                            <div className="card-body">
                                    <div className="table-responsive">
                                    <table className="table table-bordered"  width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                        <th className='five'>#</th>
                                        <th className='five' >Order Id</th>
                                        <th className='fifteen'>Customer Name</th>
                                        <th className='forty'>Address</th>
                                        <th className='fifteen'>Contact Number</th>
                                        <th className='five'>Date Ordered</th>
                                        <th  className='fifteen'>Action</th>
                                        </tr>
                                            </thead>
                                            <tbody>
                                            {
                                            Completeproducts.length>0 ?   
                                            Completeproducts.filter((product,index)=> index>=min && index<max).
                                                map((product,index) => {
                                                return (

                                                    <tr key={index+1}>
                                                        <th>{ count+index+1}</th>
                                                        <td>
                                                           {product.id}        
                                                        </td>
                                                        <td>{`${product.first_name} ${product.middle_initial} ${product.last_name}`}</td>
                                                        <td>{`${product.address_line_1} ${product.barangay} ${product.city} ${product.province}`}</td>
                                                        <td>{product.mobile_number}</td>
                                                        <td>{product.date_order}</td>
                                                        <td className="text-center">
                                                        <Link to={`/order-pdf/${product.id}` } className="btn btn-info">
                                                            <i className="fa-solid fa-print"></i>
                                                        </Link> 
                                                           
                                                          
                                        
                                                           
                                                        </td>
                                                       
                                                    </tr>
                                                   
                                                )
                                            }) 
                                            :
                                            <tr>
                                                <td colSpan="9" className="text-center">
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span className="visually-hidden">Loading...</span>
                                                <span className="ms-2">Preparing  list of products. . .</span>
                                                </td>
                                            </tr>            
                                            }
                                                
                                            </tbody>
                                            </table>
                                </div>
                                <div className="text-end">
                                <div className="btn-group" role="group" aria-label="Basic outlined example">
                                 {
                                    min>0 ?
                                    <button type="button" 
                                    className="btn btn-outline-primary" 
                                    onClick={()=>setPreviousComplete()}>Previous</button>
                                    :
                                    <button disabled type="button" 
                                    className="btn btn-outline-primary" 
                                    onClick={()=>setPreviousComplete()}>Previous</button>
                                 }   
                                {
                                    max>=products.length ?
                                    <button disabled type="button" 
                                        className="btn btn-outline-primary"
                                        onClick={()=>setNextComplete()}>Next</button>
                                    :
                                    <button type="button" 
                                        className="btn btn-outline-primary"
                                        onClick={()=>setNextComplete()}>Next</button>
                                }
                                
                                </div> 
                                </div>
                            </div>
                        </div>
                           
                    </div>
                    </div>
                </div>
            </div>
            
           
        </>
    );
}

export default AdminOrder;