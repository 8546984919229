
import React from 'react';

function Footer() {
    return (
        <>
        <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link" href="index.html">Home</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="under.html">About Us</a>
                    </li>
                  </ul>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link" href="under.html">Services</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="under.html">Reviews</a>
                    </li>
                  </ul>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link" href="under.html">Customer Service</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="under.html">Contact Us</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="under.html">Store Policies</a>
                    </li>
                  </ul>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                  <h4 className="mt-xs-4 mt-sm-3 mt-md-0 mt-lg-0">Aling Nena</h4>
                  <p>122 Calamba City, Laguna</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
        </>
    );
}

export default Footer;