import React from 'react';
import ProductCard from '../ProductCard/ProductCard';
import { useSelector } from 'react-redux';
function PageContent(props) {
  const newCart = useSelector((state) => state.allProducts.products);
    return (
        <>
        <section className="mt-2">
        <div className="container">
          <div className="row" id="promoList">
            
          <div className="container-fulid">
                <div className="row">
                {newCart.length <= 0 ?
                      <div
                      id="spin"
                      className="spinner-border text-primary mx-auto"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  :
                  <ProductCard/>
                }
                 
               
                </div>      
          </div>
              
          
          </div>
        </div>
      </section>
        </>
    );
}

export default PageContent;