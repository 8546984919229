import { PayPalButtons } from "@paypal/react-paypal-js";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './GoCartComponent.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setProducts,setCart } from '../../redux/actions/productActions';
import http from '../../http';
import PaypalCheckoutButton from "../PaypalCheckoutButton/PaypalCheckoutButton";
let prods = 0;
let total = 0;
let remove_id = 0;
let quantity_id = 0;
let order_id = 0;
function GoCartComponent(props) {
  const dispatch = useDispatch();
  const [isRemoving, setIsRemoving] = useState(0);
  const [isLoading, setIsLoading] = useState(0);
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);
  const [isAdding, setIsAdding] = useState(0);
  const [quantityAdd, setQuantityAdd] = useState(0);
  const newCart = useSelector((state) => state.allCart.products);
  const options = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const getRemoveId = (id) => {
    remove_id = id; 
    console.log(remove_id)
  }
  const getQuantityId = (id) => {
    quantity_id = id; 
    console.log(quantity_id)
  }
  const getOrderId = (id) => {
    order_id = id; 
    console.log(order_id)
  }
  const addQuantity = () => {
    setIsAdding(1);
    const newProd = {
      quantity:quantityAdd
    }
    http.put(`/cart/${quantity_id}`,newProd).then((res) => {
     
      if (res.data[0].status=='1') { 
        notifySuccess(res.data[0].message);
        dispatch(setCart(res.data[1]));
        setIsAdding(0);
      } else {
        // notifyError(res.data[0].message);
        setIsAdding(0);
      }
      
    })
    .catch(error => { 
      setIsAdding(0);
      notifyError(error.message);
      console.log(error.message);
    });

  }
  const handleApprove = (orderId) => {
    // call backend server
    const user_id = localStorage.getItem('user_id');
   
    http.put(`/order/${user_id}`).then((res) => {

      console.log(res);
      if (res.data.status == '1') {
        setPaidFor(true);
        notifySuccess(res.data.message);
        dispatch(setCart([]));
        
      } else {
        notifyError(res.data.message);
      }

    })
    .catch(error => { 
      notifyError(error.message);
      console.log(error.message);
    });
    
   
   
  }
  
  const removeProductCart = () => {
    setIsRemoving(1);
    http.delete(`/cart/${remove_id}`).then((res) => {
     
      if (res.data[0].status=='1') { 
        notifySuccess(res.data[0].message);
        dispatch(setCart(res.data[1]));
        setIsRemoving(0);
      } else {
        notifyError(res.data[0].message);
        setIsRemoving(0);
      }
      
    })
    .catch(error => { 
      setIsRemoving(0);
      notifyError(error.message);
    });
  }
  const clearProductCart = () => {
    
  }
  const notifySuccess = (result) => { 
    toast.success(`${result}`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
}
const notifyError=(result)=>{
   toast.error(`${result}`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  } 
 
  const multiplyCart = newCart.map((product) => product.quantity * product.price)
                              .reduce( (accumulator, currentValue) => accumulator + currentValue,
                              0);
  useEffect(() => {
    setIsLoading(1);
    console.log(isLoading);
  const timer = setTimeout(() => {
    setIsLoading(0);
    console.log(isLoading);
  }, 5000);
  return () => clearTimeout(timer);
}, []);
  return (
    <>
      <div className="container w-75 padding-bottom-3x mb-1 mt-5 cart">
        <div className="table-responsive shopping-cart">
       
          <table className="table w-100">
            <thead>
              <tr>
                
                <th className="text-center">#</th>
                <th className="text-center">Image</th>
                <th className="text-center">Product Name</th>
                <th className="text-center">Quantity</th>
                <th className="text-center">Price</th>
                <th className="text-center">Subtotal</th>
                <th className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody id="cartTable">
              {
                newCart.length > 0 && isLoading===0 ?
                  newCart.map((cart, index) => {
                  
                    const currency = cart.quantity * cart.price;
                    const formatted = currency.toLocaleString("en-US", currency);
                    let count = 0;
                    total = total + currency;
                    return (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">
                          <img src={cart.image} alt="" className="img-prod" />
                        </td>
                        <td className="text-center">{cart.product_name}</td>
                        <td className="text-center">{cart.quantity}</td>
                        <td className="text-center">{cart.price_label}</td>
       
                        <td className="text-center">Php {formatted}</td>
                        <td className="text-center">
                          {
                            isRemoving === 0 ?
                            <a href="#removeModal"
                              data-bs-toggle="modal"
                              onClick={() => getRemoveId(cart.id)}
                              className="btn btn-danger">
                              <i className="fa-solid fa-trash"></i>
                            </a>
                              :
                              
                                remove_id== cart.id ?
                                <a href="#"
                                data-bs-toggle="modal"
                                className="btn btn-danger disabled">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span className="visually-hidden">Please wait. We are preparing your cart...</span>
                                </a>
                                :
                                <a href="#"
                                data-bs-toggle="modal"
                                className="btn btn-danger disabled">
                                <i className="fa-solid fa-trash"></i>
                                </a>

                          }
                          {
                            isAdding === 0 ?
                            <a href="#updateModal" onClick={() => getQuantityId(cart.id)} data-bs-toggle="modal" className="btn btn-primary ms-1">
                              <i className="fa-solid fa-plus"></i>
                            </a>
                              :
                              quantity_id == cart.id ?
                              <a href="#" onClick={() => getQuantityId(cart.id)} data-bs-toggle="modal" className="disabled btn btn-primary ms-1">
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span className="visually-hidden">Please wait. We are preparing your cart...</span>
                            </a>
                                :
                                <a href="#" onClick={() => getQuantityId(cart.id)}  className="disabled btn btn-primary ms-1">
                                <i className="fa-solid fa-plus"></i>
                              </a>
                          }
                         
          
                        </td>
                      </tr>)
                  })
                  :
                  isLoading == 1 ?
                  <tr>
                  <td colSpan="7" className="text-center">
                    <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                    <span>Please wait. We are preparing your cart...</span>
                  </td>
                  </tr>
                 
                    :
                    null
                    
              }
            </tbody>
            </table>
             
          {/* Quantity */}
          <div
              className="modal fade"
              id="updateModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                     Add Quantity
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <label htmlFor="quan">Quantity</label>
                        <input type="number"
                          id="quan"
                          onChange={(e)=>setQuantityAdd(e.target.value)}
                          placeholder="Enter quantity"
                          className="form-control" />
                      </div>
                    </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                  </button>
                  {
                    isAdding === 0 ?
                      <button
                      data-bs-dismiss="modal"
                      id="checkOutBtn"
                      type="button"
                        className="btn btn-success"
                        onClick={()=>addQuantity()}
                    >
                      Update Cart
                    </button>
                      :
                      <button
                      data-bs-dismiss="modal"
                      id="checkOutBtn"
                      type="button"
                        className="btn btn-success"
                        disabled
                    >
                       Update Cart
                    </button>
                  }
                   
                  </div>
                </div>
              </div>
            </div>

          {/* End Quantity */}
          {/* Start Clear */}
          <div
              className="modal fade"
              id="clearModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Clear Cart
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>
                      Click the "Clear" button to remove all product from your cart.
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                  </button>
                  {
                    isRemoving === 0 ?
                      <button
                      data-bs-dismiss="modal"
                      id="checkOutBtn"
                      type="button"
                        className="btn btn-infp"
                        onClick={()=>clearProductCart()}
                    >
                      Clear
                    </button>
                      :
                      <button
                      data-bs-dismiss="modal"
                      id="checkOutBtn"
                      type="button"
                        className="btn btn-info"
                        disabled
                    >
                      Clear
                    </button>
                  }
                   
                  </div>
                </div>
              </div>
            </div>
          {/* End Clear */}
          <div
              className="modal fade"
              id="removeModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Remove Product
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>
                      Click the "Remove" button to remove this product from your cart.
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                  </button>
                  {
                    isRemoving === 0 ?
                      <button
                      data-bs-dismiss="modal"
                      id="checkOutBtn"
                      type="button"
                        className="btn btn-danger"
                        onClick={()=>removeProductCart()}
                    >
                      Remove
                    </button>
                      :
                      <button
                      data-bs-dismiss="modal"
                      id="checkOutBtn"
                      type="button"
                        className="btn btn-danger"
                        disabled
                    >
                      Remove
                    </button>
                  }
                   
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className="shopping-cart-footer">
          <div className="column text-lg text-end">
            Total:
            <span id="totalCart" className="text-medium fw-900">
              PHP
              {
                multiplyCart.toLocaleString("en-US", multiplyCart)
              }
            </span>
          </div>
        </div>
        <div className="shopping-cart-footer d-flex justify-content-between mt-2 mb-4">
          <div className="container-fluid">
            <div className="row">
             
            </div>
          </div>
        </div>
        <div className="shopping-cart-footer d-flex justify-content-between mt-2">
          <div className="column">
            <Link className="btn btn-outline-secondary" to="/">
              <i className="icon-arrow-left"></i>&nbsp;Back to Shopping
            </Link>
          </div>
          <div className="column ps-5">
            {
              newCart.length > 0 ?
                  <a
                  id="checkOutModal1"
                  className="btn btn-success"
                  data-bs-toggle="modal"
                  data-bs-target="#checkOutModal"
                  href="#checkOutModal"
                >
                  Checkout
                </a>
                :
                <a
                id="checkOutModal1"
                className="btn btn-success disabled"
                data-bs-toggle="modal"
                data-bs-target="#checkOutModal"
                href="#"
              >
                Checkout
              </a>
            }
           

            <div
              className="modal fade"
              id="checkOutModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Checkout Product
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="container-fluid">
                      <div className="row">
                      <div className="col-12">
                <h5>Customer Information</h5>
              </div>
              <div className="col-12 mt-3">
                <h6>Customer Name: <span className="fw-bolder">{ localStorage.getItem("fullname")}</span></h6>
              </div>
              <div className="col-12 mt-1">
                <h6>Mobile Number: <span className="fw-bolder">{ localStorage.getItem("mobile")}</span></h6>
              </div>
              <div className="col-12 mt-1">
                <h6>Address: <span className="fw-bolder">{ `${localStorage.getItem("address")} ${localStorage.getItem("barangay")}  ${localStorage.getItem("city")}  ${localStorage.getItem("province")}`}</span></h6>
              </div>
                        <div className="col-12 mt-2">
                          <div className="paypal-button-container">
                            <PayPalButtons
                              forceReRender={["PHP"]}
                              style={{
                              color: "gold",
                              layout: "horizontal",
                              height: 48,
                              tagline: false,
                              currency:"PHP",
                              shape: "pill"
                            }}
                              onClick={(data, actions) => {
                                // Validate on button click
                                const hasAlreadyBought = false;
                                if (hasAlreadyBought) {
                                  // setError
                                
                                  setError(`ERROR: Already bought.`);
                                  notifyError("Transaction is already been made!");
                                  return actions.reject();
                                } else {
                                  return actions.resolve();
                                }
                                
                              }}
                              createOrder={(data, actions) => {
                                return actions.order.create({
                                  purchase_units: [
                                    {
                                      description: "Checkout from Aling Nena",
                                      amount: {
                                        currency_code:"PHP",
                                        value: multiplyCart
                                        
                                      }
                                    }
                                  ]
                                })
                              }}
                              onApprove={async (data, actions) => {
                                const order = await actions.order.capture();
                                console.log("order", order);
                                handleApprove(data.orderId);
                              }}
                              onCancel={() => { 
                                // Display cancel message
                               
                                notifyError("Order checkout has been cancelled!");
                              }}
                              onError={(err) => { 
                                setError(err);
                                notifyError("Paypal Checkout error!");
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GoCartComponent;
