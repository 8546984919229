import React, { useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
function Logout(props) {
    const navigate = useNavigate();
    const gotoLogout = () => {
        localStorage.clear();
        navigate("/");
    }
    useEffect(() => {
        gotoLogout();
    },[]);
    return (
        <div>
            
        </div>
    );
}

export default Logout;