import React, { useState, useEffect } from "react";
import { Link,useParams } from "react-router-dom";
import "./Header.css";
import { useDispatch, useSelector } from 'react-redux';
import { setProducts, searchWord,setSearchProducts,setSearchWomenProducts,setSearchMenProducts,setSearchKidProducts } from '../../redux/actions/productActions';
import http from '../../http';
function Header(props) {
  const dispatch = useDispatch();
  const [searchingWord, setSearchingWord] = useState('');
  const products = useSelector((state) => state.searchProductsMen.products);
  const filteredProduct = useSelector((state) => state.searchProductsMen.products);
  const newSearchWord = useSelector((state) => state.searchWord);
  const newCart = useSelector((state) => state.allCart.products);
  const fetchProduct = async () => {
    const searchProduct = {
      'page':newSearchWord.page
    }
    http.post(`/search-product`,searchProduct).then((res) => {
      if (res.data[0].status=='1') { 
        dispatch(setProducts(res.data[1]));
        dispatch(setSearchProducts(res.data[1]));
        dispatch(setSearchWomenProducts(res.data[2]));
        dispatch(setSearchMenProducts(res.data[3]));
        dispatch(setSearchKidProducts(res.data[4]));
        console.log('Home ',res.data[1]);
        console.log('WOmen',res.data[2]);
        console.log('Men',res.data[3]);
        console.log('Kid',res.data[4]);
      }
      
  })
  .catch(error => { 
    console.error(error.message);
    });
  }

  const filteringProducts = () => {
    const transferProduct = [...products];
    const newSearchProduct = transferProduct.filter((product)=>product.product_name.toLowerCase().indexOf(searchingWord.toLowerCase())>-1);
    dispatch(setSearchMenProducts(newSearchProduct));
    
 
  }

  useEffect(() => {
    fetchProduct();
  }, [newSearchWord]);
  useEffect(() => {
    filteringProducts();
  }, [searchingWord]);
  useEffect(() => {
    
  }, [newCart]);
  return (
    <>
      <header>
        <nav className="navbar fixed-top navbar-expand-sm navbar-light bg-light">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* <Link  to="/"> */}
              <h1 className="bg-light brand-class border-0">Aling Nena</h1>
            {/* </Link> */}
            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className="nav-link active me-xl-5 me-lg-4 me-md-3"
                    aria-current="page"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link me-xl-5 me-lg-4 me-md-3" to="/women">
                    Women
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link me-xl-5 me-lg-4 me-md-3" to="/men">
                    Men
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link me-xl-5 me-lg-4 me-md-3" to="/kid">
                    Kids
                  </Link>
                </li>

                <li className="nav-item d-xs-block d-sm-none">
                  {
                    
                    localStorage.getItem('user_id') == undefined ? 
                      <Link
                        id="loginXs"
                        className="nav-link me-xl-5 me-lg-4 me-md-3"
                        to="/login"
                      >
                        Login
                  </Link>
                  :
                  <Link
                    id="loginXs"
                    className="nav-link me-xl-5 me-lg-4 me-md-3"
                    to="/logout"
                  >
                    Logout
                  </Link>
                  }
                  
                </li>
                {
                  localStorage.getItem('user_id') != undefined ?
                
                    <li className="nav-item d-xs-block d-sm-none">
                      <Link
                        className="nav-link me-xl-5 me-lg-4 me-md-3 d-flex"
                        to="/cart"
                      >
                        Cart
                        <span id="cart-sm" className="badge bg-danger">
                          0
                        </span>
                      </Link>
                    </li>
                    :null
                    }
                    <li className="nav-item mt-3 mb-3">
                  <div className="input-group input-group-md me-5">
                    <span
                      className="input-group-text search-icon d-xs-block d-sm-none"
                      id="search"
                    >
                      <i className="fa-solid fa-magnifying-glass fs-6"></i>
                    </span>
                    {/* if sm */}
                    <input
                      id="searchLg"
                      className="form-control search-input d-xs-block d-sm-none"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      
                      aria-describedby="search"
                     
                    />
                  </div>
                </li>
            
              </ul>
            </div>
           
             
            <div className="input-group input-group-sm me-5">
              <span
                className="input-group-text search-icon d-none d-md-block"
                id="search"
              >
                <i className="fa-solid fa-magnifying-glass fs-6 mt-2"></i>
              </span>
              {/* if lg */}
              <input
                id="searchXl"
                className="form-control search-input d-none f-6 d-md-block"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => {
                  setSearchingWord(e.target.value);
                }}
                aria-describedby="search"
              
              />
            </div>
            

            <div className="justify-content-center d-none d-sm-block d-md-block d-lg-none mt-2">
              <ul className="navbar-nav">
               
                    <li className="nav-item d-sm-block d-md-none">
                    <a
                      className="nav-link me-xl-5 me-lg-4 me-md-3"
                      href="#searchModal"
                      data-bs-toggle="modal"
                      data-bs-target="#searchModal"
                    >
                      <i className="fa-solid fa-magnifying-glass fs-6"></i>
                    </a>
  
                    <div
                      className="modal fade"
                      id="searchModal"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Search Product
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <input
                              id="searchModalText"
                              type="text"
                              placeholder="Search product here"
                             
                            
                              className="form-control"
                            />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                
              
                <li className="nav-item">
                  {
                    
                    localStorage.getItem('user_id') == undefined ?
                      <Link
                        id="loginIcon"
                        className="nav-link me-xl-5 me-lg-4 me-md-3"
                        to="/login"
                      >
                        <iconify-icon
                          width="1.4em"
                          icon="material-symbols:lock"
                        ></iconify-icon>
                      </Link>
                      :
                      <Link
                        id="loginIcon"
                        className="nav-link me-xl-5 me-lg-4 me-md-3"
                        to="/logout"
                      >
                        <iconify-icon
                          width="1.4em"
                          icon="material-symbols:lock-open"
                        ></iconify-icon>
                      </Link>
                }
                </li>
                {
                  localStorage.getItem('user_id')!=undefined ?
                
                <li className="nav-item">
                  <Link
                    className="nav-link me-xl-5 me-lg-4 me-md-3 d-flex"
                    to="/cart"
                  >
                    
                    <iconify-icon
                      width="1.4em"
                      icon="material-symbols:shopping-cart-rounded"
                    ></iconify-icon>
                    <span id="cart-lg" className="ms-1 badge bg-danger">
                      { newCart.length}
                    </span>
                  </Link>
                    </li>
                    :null
                    }
              </ul>
            </div>

            <div className="justify-content-center d-none d-lg-block">
              <ul className="navbar-nav">
                <li className="nav-item">
                  {
                    
                    localStorage.getItem('user_id') == undefined ?
                      <Link
                        id="loginLg"
                        className="nav-link me-xl-5 me-lg-4 me-md-3"
                        to="/login"
                      >
                        Login
                      </Link>
                      :
                      <Link
                        id="loginLg"
                        className="nav-link me-xl-5 me-lg-4 me-md-3"
                        to="/logout"
                      >
                        Logout
                      </Link>
                  }
                </li>
                {
                  localStorage.getItem('user_id') ?
               
                <li className="nav-item">
                  <Link
                    className="nav-link me-xl-5 me-lg-4 me-md-3 d-flex"
                    to="/cart"
                  >
                    Cart
                    <span id="cart-icon" className="ms-1 badge bg-danger">
                    { newCart.length}
                    </span>
                  </Link>
                    </li>
                    :null
                     }
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
