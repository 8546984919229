import { ActionTypes } from "../constants/action-types"

const initialState = {
    products: [ ]
}
const cartInitialState = {
    products: [
       
    ]
}
const orderInitialState = {
    products: [
        
    ]
}
const orderProductInitialState = {
    products: [
        
    ]
}

export const orderCartProductReducer = (state=orderProductInitialState, { type,payload}) => {
    switch (type) {
        case ActionTypes.ORDER_ALL_PRODUCTS:
            return {...state,products:payload};
        default:
            return state;
    }
}

export const orderPendingProductReducer = (state=orderProductInitialState, { type,payload}) => {
    switch (type) {
        case ActionTypes.ORDER_ALL_PENDING_PRODUCTS:
            return {...state,products:payload};
        default:
            return state;
    }
}

export const orderCompleteProductReducer = (state=orderProductInitialState, { type,payload}) => {
    switch (type) {
        case ActionTypes.ORDER_ALL_COMPLETE_PRODUCTS:
            return {...state,products:payload};
        default:
            return state;
    }
}

export const productReducer = (state=initialState, { type,payload}) => {
    switch (type) {
        case ActionTypes.SET_PRODUCTS:
            return {...state,products:payload};
        default:
            return state;
    }
}

export const cartProductReducer = (state=cartInitialState, { type,payload}) => {
    switch (type) {
        case ActionTypes.CART_PRODUCTS:
            return {...state,products:payload};
        default:
            return state;
    }
}

export const orderProductReducer = (state=orderInitialState, { type,payload}) => {
    switch (type) {
        case ActionTypes.ORDER_PRODUCTS:
            return {...state,products:payload};
        default:
            return state;
    }
}

export const searchProductReducer = (state=initialState, { type,payload}) => {
    switch (type) {
        case ActionTypes.SEARCH_PRODUCTS:
            return {...state,products:payload};
        default:
            return state;
    }
}
export const searchProductWomenReducer = (state=initialState, { type,payload}) => {
    switch (type) {
        case ActionTypes.SEARCH_PRODUCTS_WOMEN:
            return {...state,products:payload};
        default:
            return state;
    }
}
export const searchProductMenReducer = (state=initialState, { type,payload}) => {
    switch (type) {
        case ActionTypes.SEARCH_PRODUCTS_MEN:
            return {...state,products:payload};
        default:
            return state;
    }
}
export const searchProductKidReducer = (state=initialState, { type,payload}) => {
    switch (type) {
        case ActionTypes.SEARCH_PRODUCTS_KID:
            return {...state,products:payload};
        default:
            return state;
    }
}

export const selectedProductReducer = (state = {}, { type,payload}) => {
    switch (type) {
        case ActionTypes.SELECTED_PRODUCT:
            return { ...state, ...payload};
        default:
            return state;
    }
}

const search = { 
    search: "",
    page:"HOME"
}
export const searchWordReducer = (state = search, { type,payload}) => {
    switch (type) {
        case ActionTypes.SEARCH_WORD:
            return { ...state, ...payload};
        default:
            return state;
    }
}



