import { ActionTypes } from "../constants/action-types";
export const setProducts = (products) => {
    return {
        type: ActionTypes.SET_PRODUCTS,
        payload: products,
    };
};
export const setCart = (products) => {
    return {
        type: ActionTypes.CART_PRODUCTS,
        payload: products,
    };
};
export const setOrderProduct = (products) => {
    return {
        type: ActionTypes.ORDER_ALL_PRODUCTS,
        payload: products,
    };
};
export const setOrderPendingProduct = (products) => {
    return {
        type: ActionTypes.ORDER_ALL_PENDING_PRODUCTS,
        payload: products,
    };
};
export const setOrderCompleteProduct = (products) => {
    return {
        type: ActionTypes.ORDER_ALL_COMPLETE_PRODUCTS,
        payload: products,
    };
};
export const setOrder = (products) => {
    return {
        type: ActionTypes.ORDER_PRODUCTS,
        payload: products,
    };
};
export const setSearchProducts = (products) => {
    return {
        type: ActionTypes.SEARCH_PRODUCTS,
        payload: products,
    };
};
export const setSearchWomenProducts = (products) => {
    return {
        type: ActionTypes.SEARCH_PRODUCTS_WOMEN,
        payload: products,
    };
};
export const setSearchMenProducts = (products) => {
    return {
        type: ActionTypes.SEARCH_PRODUCTS_MEN,
        payload: products,
    };
};
export const setSearchKidProducts = (products) => {
    return {
        type: ActionTypes.SEARCH_PRODUCTS_KID,
        payload: products,
    };
};
export const selectedProduct = (product) => {
    return {
        type: ActionTypes.SELECTED_PRODUCT,
        payload: product,
    };
};

export const searchWord = (word) => {
    return {
        type: ActionTypes.SEARCH_WORD,
        payload: word,
    };
};







