export const ActionTypes = {
    SET_PRODUCTS: "SET_PRODUCTS",
    SELECTED_PRODUCT: "SELECTED_PRODUCT",
    SEARCH_PRODUCTS : "SEARCH_PRODUCTS",
    SEARCH_PRODUCTS_WOMEN : "SEARCH_PRODUCTS_WOMEN",
    SEARCH_PRODUCTS_MEN : "SEARCH_PRODUCTS_MEN",
    SEARCH_PRODUCTS_KID : "SEARCH_PRODUCTS_KID",
    CART_PRODUCTS : "CART_PRODUCTS",
    ORDER_PRODUCTS : "ORDER_PRODUCTS",
    ORDER_ALL_PRODUCTS: "ORDER_ALL_PRODUCTS",
    ORDER_ALL_PENDING_PRODUCTS : "ORDER_ALL_PENDING_PRODUCTS",
    ORDER_ALL_COMPLETE_PRODUCTS : "ORDER_ALL_COMPLETE_PRODUCTS",
    REMOVED_SELECTED_PRODUCT: "REMOVED_SELECTED_PRODUCT",
    SEARCH_WORD: "SEARCH_WORD",
 
}