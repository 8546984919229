 import React from 'react';
 
 function Subscription() {
    return (
        <>
        <section className="mt-4">
        <div className="container subscription">
          <div className="row">
            <div className="subs col-xxl-12 col-xl-12 col-lg-12">
              <h3>Subscribe to our Newsletter</h3>
              <p className="mt-4">
                To get discount on the first purchase and access to closed
                shares and sales.
              </p>
              <span className="text-white"></span>
              <div className="new-form mt-4">
                <div className="input-container">
                  <iconify-icon
                    width="1.5em"
                    className="text-white"
                    icon="material-symbols:mail"
                  ></iconify-icon>
                  <input
                    type="email"
                    id="subscribeHome"
                    placeholder="Your mail"
                  />
                </div>

                <button id="sub-btn" className="ms-2">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </section>
        </>
    );
 }
 
 export default Subscription;