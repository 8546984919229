import React,{useEffect,useRef,useState} from 'react';
import ProductModal from '../ProductModal/ProductModal';
import { useDispatch,useSelector } from 'react-redux';
import "./AdminProduct.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setProducts } from '../../redux/actions/productActions';
import http from '../../http';
import { Link } from "react-router-dom";
// import env from "react-dotenv";
var quantity_id = 0;
var retrieve_id = 0;
var remove_id = 0;
var btn_remove_index = 0;
var newquan = 0;
var min = 0;
var max = 10;
var count = 0;
function AdminProduct() {
    let tbClass = "text-danger"; 
    const allRemove = useRef([]);
    const [newQuantity, setNewQuantity] = useState(0);
    var myHeaders = new Headers();
    const dispatch = useDispatch();
    myHeaders.append("Content-Type", "application/json");
    const products = useSelector((state) => state.allProducts.products);
    const [isUpdating, setIsUpdating] = useState(0);
    const [isRetrieving, setIsRetrieving] = useState(0);
    const [isRemoving, setIsRemoving] = useState(0);
    const [searching, setSearching] = useState('');
    const api = 'http://localhost/my-app-be-test/my-app-be/public/';
    console.log(products);
    const retrieveProduct = (id) => { 
        
        const newProduct = [...products];
        const singleProduct = newProduct.filter((prod, index) => prod.id==id);
        const index = newProduct.map(prod => prod.id).indexOf(id);
        retrieve_id = id;
      
        setIsRetrieving(1);
        const updatedProduct = {
            process: 'RETRIEVEPRODUCT'
        }
        http.put(`/update-status/${id}`, updatedProduct).then((result) => {
           
            const newProduct = [...products];
            singleProduct[0].status = 'active';
            newProduct.splice(index, 1, singleProduct[0]);
            dispatch(setProducts(newProduct));
            setIsRetrieving(0);
            notifySuccess(result.data.message);
            
        })
        .catch(error => { 
            setIsRetrieving(0);
                    notifyError(error.message);
            });
    }
    const removeProduct = (id) => {
        remove_id = id;
        const newProduct = [...products];
        const singleProduct = newProduct.filter((prod, index) => prod.id==id);
        const index = newProduct.map(prod => prod.id).indexOf(id);
        btn_remove_index = index;
       
        setIsRemoving(1);
        const updatedProduct = {
            process: 'REMOVEPRODUCT'
        }
        http.put(`/update-status/${id}`, updatedProduct).then((result) => {
           
            const newProduct = [...products];
            singleProduct[0].status = 'removed';
            newProduct.splice(index, 1, singleProduct[0]);
            dispatch(setProducts(newProduct));
            setIsRemoving(0);
            notifySuccess(result.data.message);
            console.log(result.data.status=='1'); 
        })
        .catch(error => { 
            setIsRemoving(0);
                    notifyError(error.message);
            });

      
    }
    const quantityProduct = (id) => {
        quantity_id = id;
        console.log(quantity_id);
    }
    const addQuantity = () => {
        setIsUpdating(1);
        const newProduct = [...products];
        const singleProduct = newProduct.filter((prod, index) => prod.id===quantity_id);
        const index = newProduct.map(prod => prod.id).indexOf(quantity_id);
        
        let quan = singleProduct[0].quantity;
        let sum = parseInt(quan) + parseInt(newquan);
       
       

        const updatedProduct = {
            quantity: sum
        }
        http.put(`/update-quantity/${quantity_id}`, updatedProduct).then((result) => {
           
            const newProduct = [...products];
            singleProduct[0].quantity = sum;
                newProduct.splice(index, 1, singleProduct[0]);
                dispatch(setProducts(newProduct));
                setIsUpdating(0);
                notifySuccess(result.data.message);
            
        })
        .catch(error => { 
                    setIsUpdating(0);
                    notifyError(error.message);
            });
    }
   
    useEffect(() => {
        newquan = newQuantity;
      }, [newQuantity]);
    useEffect(() => {
        <></>
      }, [isUpdating]);
      const notifySuccess = (result) => { 
        toast.success(`${result}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
    }
    const notifyError=(result)=>{
       toast.error(`${result}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
    }
    const setNext = () => {
        min = max;
        max = max + 10;
        const newProd = [...products];
        dispatch(setProducts([]));
        dispatch(setProducts(newProd));
        count = min;
    }
    const setPrevious = () => {
        max = min;
        min = min - 10;
        const newProd = [...products];
        dispatch(setProducts([]));
        dispatch(setProducts(newProd));
        count = min;
    }
    return (
        <>
            <div className="row mt-3">
                <div className="col-12">
                    <div className="row">
                    <div className="col-6">
                        <h1 className="h2">Product List</h1> 
                    </div>
                    <div className="col-6 d-flex justify-content-end">      
                            <ProductModal/>
                    </div>
                    
                    <div className="col-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">List of Products</h6>
                            </div>
                            <div className="card-body">
                                    <div className="table-responsive">
                                    <table className="table table-bordered"  width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                        <th className='five'>#</th>
                                        <th className='five' >Product Image</th>
                                        <th className='ten'>Category</th>
                                        <th className='ten'>Product Name</th>
                                        <th className='twenty'>Description</th>
                                        <th className='ten'>Price</th>
                                        <th className='five'>Quantity</th>
                                        <th className='five'>Status</th>
                                        <th  className='thirty'>Action</th>
                                        </tr>
                                            </thead>
                                            <tbody>
                                            {
                                            products.length>0 ?   
                                                products.filter((product,index)=> index>=min && index<max).
                                                map((product,index) => {
                                                   
                                                   
                                                    return (
                                                  

                                                    <tr key={index + 1}>
                                                            
                                                            <th  >{ count+index+1}</th>
                                                        <td>
                                                            <img src={product.image} alt="" className="productImage"  />        
                                                        </td>
                                                        <td  >{product.category_name}</td>
                                                        <td  >{product.product_name }</td>
                                                        <td  >{product.description}</td>
                                                        <td  >{product.price }</td>
                                                        <td  >{product.quantity}</td>
                                                        <td  >{product.status }</td>
                                                        <td>
                                                            <Link to={`/admin-product/${product.id}`}  className="btn btn-success me-xxl-1 me-xl-1 me-lg-1 me-md-0 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-1 mb-sm-1 mb-1">
                                                                <i className="fa-solid fa-pen-to-square"></i>
                                                            </Link>
                                                            {
                                                                product.status == "active" ?
                                                                        isRemoving == 0 ?     
                                                                            <button className="btn btn-danger  me-xxl-1 me-xl-1 me-lg-1 me-md-0 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-1 mb-sm-1 mb-1" onClick={() => removeProduct(product.id)}>
                                                                            <i className="fa-solid fa-trash"></i>   
                                                                            </button>
                                                                        :
                                                                        remove_id == product.id ?
                                                                        <button disabled className="btn btn-danger  me-xxl-1 me-xl-1 me-lg-1 me-md-0 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-1 mb-sm-1 mb-1" onClick={() => removeProduct(product.id)}>
                                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                                        <span className="visually-hidden">Loading...</span>
                                                                        </div>  
                                                                        </button>
                                                                            :
                                                                        <button disabled className="btn btn-danger  me-xxl-1 me-xl-1 me-lg-1 me-md-0 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-1 mb-sm-1 mb-1" onClick={() => removeProduct(product.id)}>
                                                                             <i className="fa-solid fa-trash"></i>   
                                                                        </button>
                                                                        
                                                                    
                                                                    :
                                                                    isRetrieving == 0 ?         
                                                                        <button className="btn btn-info  me-xxl-1 me-xl-1 me-lg-1 me-md-0 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-1 mb-sm-1 mb-1" onClick={() => retrieveProduct(product.id)}>
                                                                            <i className="fa-solid fa-sync"></i>
                                                                        </button>
                                                                        :
                                                                        product.id == retrieve_id ?
                                                                            <button disabled className="btn btn-info  me-xxl-1 me-xl-1 me-lg-1 me-md-0 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-1 mb-sm-1 mb-1" >
                                                                            <div className="spinner-border spinner-border-sm" role="status">
                                                                                <span className="visually-hidden">Loading...</span>
                                                                                </div>
                                                                            </button>
                                                                        :
                                                                        <button disabled className="btn btn-info  me-xxl-1 me-xl-1 me-lg-1 me-md-0 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-1 mb-sm-1 mb-1" >
                                                                         <i className="fa-solid fa-sync"></i>
                                                                        </button>
                                                            }
                                                            {
                                                                isUpdating == 0 ?
                                                                <a href="#quantityModal" data-bs-toggle="modal" className="btn btn-primary" onClick={()=>quantityProduct(product.id)}>
                                                                <i className="fa-solid fa-plus"></i>
                                                                </a>
                                                                :
                                                                quantity_id==product.id ?
                                                                <a href="#quantityModal" data-bs-toggle="modal" className="disabled btn btn-primary" onClick={()=>quantityProduct(product.id)}>
                                                                <div className="spinner-border spinner-border-sm" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                                </a>
                                                                :
                                                                <a href="#quantityModal" data-bs-toggle="modal" className="disabled btn btn-primary" onClick={()=>quantityProduct(product.id)}>
                                                                <i className="fa-solid fa-plus"></i>
                                                                </a>
                                                            }
                                                          
                                        
                                                           
                                                        </td>
                                                       
                                                    </tr>
                                                   
                                                )
                                            }) 
                                            :
                                            <tr>
                                                <td colSpan="9" className="text-center">
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span className="visually-hidden">Loading...</span>
                                                <span className="ms-2">Preparing  list of products. . .</span>
                                                </td>
                                            </tr>            
                                            }
                                                
                                            </tbody>
                                            </table>
                                </div>
                                <div className="text-end">
                                <div className="btn-group" role="group" aria-label="Basic outlined example">
                                 {
                                    min>0 ?
                                    <button type="button" 
                                    className="btn btn-outline-primary" 
                                    onClick={()=>setPrevious()}>Previous</button>
                                    :
                                    <button disabled type="button" 
                                    className="btn btn-outline-primary" 
                                    onClick={()=>setPrevious()}>Previous</button>
                                 }   
                                {
                                    max>=products.length ?
                                    <button disabled type="button" 
                                        className="btn btn-outline-primary"
                                        onClick={()=>setNext()}>Next</button>
                                    :
                                    <button type="button" 
                                        className="btn btn-outline-primary"
                                        onClick={()=>setNext()}>Next</button>
                                }
                                
                                </div> 
                                </div>
                            </div>
                        </div>
                           
                    </div>
                       
                  
                    </div>
                </div>
            </div>
            <div className="modal fade" id="quantityModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Add Product</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         
      </div>
      <div className="modal-body">
                <div className="row">
                  <div className="col-12 mb-2">
                    <label htmlFor="quantity" className="form-label">Quantity</label>
                    <input type="number"
                      className="form-control"
                      value={newQuantity}
                      placeholder="10"
                      onChange={(e)=>{
                          setNewQuantity(e.target.value)
                          console.log(e.target.value)
                      }
                      }
                    />
                  </div> 
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" data-bs-dismiss="modal" className="btn btn-primary btnAdd" onClick={addQuantity} >Add Quantity</button>
      </div>
    </div>
  </div>
        </div>
           
        </>
    );
}

export default AdminProduct;