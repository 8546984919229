import React, { useState,useEffect} from 'react';
import CustomerProductList from '../CustomerProductList/CustomerProductList';
import { useParams } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import http from '../../http';
import { setOrderProduct } from '../../redux/actions/productActions';
import { useDispatch, useSelector } from 'react-redux';
function CustomerProduct() {
    const { orderId } = useParams();
    const dispatch = useDispatch();
    const newCart = useSelector((state) => state.allCart.products);
    const allOrderProduct = useSelector((state) => state.orderAllProducts.products);
    const fetchProduct = async () => {
        if (localStorage.getItem('user_id') != undefined) { 
          const user_id = localStorage.getItem('user_id');
          http.get(`/order-list/${orderId}`).then(res => {
                if (res.data[0].status=='1') { 
                    dispatch(setOrderProduct(res.data[1]));
                   
                }
        }).catch(error => console.log('error', error));
        }
        
    }
    
    useEffect(() => {
        fetchProduct();
    },[]);
    return (
        <>
            <Header />
            <CustomerProductList/>
            <Footer/>
        </>
    );
}

export default CustomerProduct;