import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../HeaderMen/Header";
import ProductContent from "../ProductContentMen/ProductContent";
import Subscription from "../Subscription/Subscription";
import Title from "../Title/Title";
import { getProduct } from "../Helper/ProductList";
import { useDispatch, useSelector } from 'react-redux';
import { setProducts,searchWord,setCart } from '../../redux/actions/productActions';
import http from '../../http';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function MenPage() {
  const newSearchWord = useSelector((state) => state.searchWord);
  const newCart = useSelector((state) => state.allCart.products);
  const dispatch = useDispatch();
  
  const fetchProduct = async () => {
    if (localStorage.getItem('user_id') != undefined) { 
      const user_id = localStorage.getItem('user_id');
      http.get(`/cart/${user_id}`).then(res => {
            if (res.data[0].status=='1') { 
                dispatch(setCart(res.data[1]));
            }
      }).catch(error => { }
        // console.log('error', error)
      );
      
    }
    
}
useEffect(() => { 
    fetchProduct();
}, [])


  useEffect(() => {
    const initHome = {
      'page': 'WOMEN',
      'search':''
    }
    dispatch(searchWord(initHome));
   
  }, []);
  return (
    <>
      <Header />
      <Title />

      <ProductContent />

      <Subscription />
      <Footer />
    </>
  );
}

export default MenPage;
