
import React, { useState,useEffect} from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ProductContent from '../ProductContent/ProductContent';
import Subscription from '../Subscription/Subscription';
import Title from '../Title/Title';
import { getProduct } from '../Helper/ProductList';
function Products() {
const products = [...getProduct()];
  const [searchs,setSearches] = useState('All');
  const searchProduct = (search) => { 
    setSearches(search);
    }
    useEffect(() => {
        setSearches('All');
      },[]);
    return (
        <>
            <Header searchProduct={ searchProduct} />
            <Title />
            
            <ProductContent/>

            <Subscription />
            <Footer/>
        </>
    );
}

export default Products;