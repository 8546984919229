import React, { useEffect,useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./ProductCard.css";
import http from '../../http';
import { ToastContainer, toast } from 'react-toastify';
import { setProducts, searchWord,setSearchProducts,setCart } from '../../redux/actions/productActions';
import 'react-toastify/dist/ReactToastify.css';

var product_id = 0;
function ProductCard(props) {
    const [buying, setBuying] = useState(0);
    const products = useSelector((state) => state.allProducts.products);
    const filteredProducts = useSelector((state) => state.searchProductsWomen.products);
    const searchWord = useSelector((state) => state.searchWord);
    const newCart = useSelector((state) => state.allCart.products);
    const dispatch = useDispatch();
    const addCart = (id) => {
        setBuying(1);
        product_id = id;
        if (localStorage.getItem('user_id')!=undefined) { 
            const mainCart = document.querySelectorAll('.main-cart');
            // mainCart.forEach(cart => {
            //     cart.setAttribute('disabled',true);
            // })
                const cartProduct = {
                    'customer_id': localStorage.getItem('user_id'),
                    'product_id': id,
                    
                  }
                  http.post(`/cart`,cartProduct).then((res) => {
                    if (res.data[0].status=='1') {
                        notifySuccess(res.data[0].message);
                        dispatch(setCart(res.data[1]));
                        // mainCart.forEach(cart => {
                        //     cart.removeAttribute('disabled');
                        // })
                        setBuying(0);
                    } else {
                        notifyError(res.data[0].message);
                        // mainCart.forEach(cart => {
                        //     cart.removeAttribute('disabled');
                        // })
                        setBuying(0);
                    }
                      
                    
                })
                .catch(error => { 
                    console.error(error.message);
                    notifyError(error.message);
                    setBuying(0);
                  });
        } else {
            notifyError("Please login first before you can add this product to your cart!");
        }
      
    }
    const notifySuccess = (result) => { 
        toast.success(`${result}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
    }
    const notifyError=(result)=>{
       toast.error(`${result}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
    }
    // useEffect(() => {
    
    // }, [newCart]);
    // useEffect(() => {
        
    // },[filteredProducts]);
    useEffect(() => {
    
    }, []);
    useEffect(() => {
        
    },[]);
    return (
        <>
            {
                filteredProducts.map((product, index) => {
                    return (
                        <div key={index} className="productChoose col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
                            <div className="card cardPromo overflow-hidden">
                                <img src={product.image} alt="" className="card-image-top image-card" />
                                <div className="card card-body rounded-0 body-card" >
                                    <p className="card-text description mt-3">
                                        {product.product_name}
                                    </p>
                                    <p className="description mt-2">
                                        {product.price_label}
                                    </p>
                                    <p className="d-flex justify-content-evenly">
                                        {
                                            buying === 1 && product_id==product.id ?
                                            <button className="btn btn-success main-cart">
                                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            </button> 
                                                :
                                            <button className="btn btn-success main-cart"
                                                onClick={ ()=> addCart(product.id)}>
                                            <i className="fa-solid fa-cart-plus"></i>
                                            </button>    
                                        }
                                      
                                       
                                    </p>
                                </div>
                            </div>
                    
                        </div>
                    )
                })
            }
           
        </>
    );
}

export default ProductCard;