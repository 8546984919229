import React,{useEffect,useState,useRef} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { selectedProduct } from '../../redux/actions/productActions';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../http';
function AdminUpdateProduct() {
    const element = useRef(null);
    const btnUpdate = useRef(null);
    const varDisabled = ['disabled'];
    let imageInitialLink = "";
    const product = useSelector((state) => state.product);
    const products = useSelector((state) => state.allProducts.products);
    const [productName, setProductName] = useState('');
    const [quantityFloor, setQuantityFloor] = useState(0);
    const [productDescription, setProductDescription] = useState('');
    const [productCategory, setProductCategory] = useState('');
    const [productPrice, setProductPrice] = useState(0.00);
    const [productImage, setProductImage] = useState('https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg');
    const [isUploading, setIsUploading] = useState(0);
    const [isUpdating, setIsUpdating] = useState(0);

    const dispatch = useDispatch();
    const api = "http://localhost/my-app-be-test/my-app-be/public/";
    
    const { productId } = useParams();
    
    const fetchProductDetail = async () => {
        http.get(`/products/${productId}`).then(res => {
            console.log(res.data[0]);
                if (res.data[0].status=='1') { 
                    if (res.data[0].status=='1') { 
                        dispatch(selectedProduct(res.data[1]));
                        setProductName(res.data[1].product_name);
                        setProductDescription(res.data[1].description);
                        setQuantityFloor(res.data[1].floor_quantity);
                        setProductCategory(res.data[1].category_name);
                        setProductPrice(res.data[1].price);
                        setProductImage(res.data[1].image);
                        imageInitialLink = res.data[1].image;
                    }
                }
        }).catch(error => console.log('error', error));

    }
    const notifySuccess = (result) => { 
        toast.success(`${result}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
    }
    const notifyError=(result)=>{
       toast.error(`${result}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
    }
    const updateProduct = () => {
        setIsUpdating(1);
        const updatedProduct = {
            product_name: productName,
            category_name:productCategory,
            description: productDescription,
            image:productImage,
            price: productPrice,
            quantity_floor:10
          }
        http.put(`/products/${productId}`, updatedProduct).then((result) => {
                setIsUpdating(0);
           
            if (result.data.status=='1') {
                notifySuccess(result.data.message); 
            } else {
                notifyError(result.data.message);
            }
        })
        .catch(error => { 
                    setIsUpdating(0);
                    notifyError(error.message);
            });
      
      }
    const uploadImage = (files) => {
        setIsUploading(1);
        const formData = new FormData();
        formData.append("file",files[0]);
        formData.append("upload_preset","wmnayrn3");
        axios.post("https://api.cloudinary.com/v1_1/codestudio28/image/upload", formData)
          .then((res) => { 
              setProductImage(res.data.url);
              setIsUploading(0);
          }).catch((err) => { 
              console.log(err);
              setIsUploading(0);
          });
          
      }
    useEffect(() => {
        if (productId && productId !== "")  {
            fetchProductDetail();
        }
    }, [productId]);
        useEffect(() => {
        if (isUpdating==1) {
            btnUpdate.current.classList.add('disabled');
            btnUpdate.current.innerText="Saving product...";
        } else {
            if (btnUpdate.current != null) { 
                btnUpdate.current.classList.remove('disabled');
                btnUpdate.current.innerText = "Update Product";
            }
           
        }
      }, [isUpdating]);
    useEffect(() => {
        if (isUploading==1) {
            element.current.classList.add('disabled');
            element.current.innerText = "Uploading product image....";
            
            btnUpdate.current.classList.add('disabled');
        } else {
            if (element.current!=null) { 
                element.current.classList.remove('disabled');
                btnUpdate.current.classList.remove('disabled');
                element.current.innerText = "Upload Product Image";
            } 
        }
    }, [isUploading]);
    useEffect(() => {
        
      }, [productImage]);
   
    
      useEffect(() => {
       
        if ((productName=="")||(productDescription=="")||(productPrice==0.00)||(productImage==imageInitialLink)) {
            if (btnUpdate.current != null) { 
                btnUpdate.current.classList.add('disabled');
            }
            
        } else {
            if (btnUpdate.current!=null) { 
                btnUpdate.current.classList.remove('disabled');
            } 
          
        }
      }, [productName,productDescription,productCategory,productPrice,productImage,productImage]);
      
    return (
       
        <>
            {Object.keys(product).length === 0 ? (
                 <div className="row mt-3">
                 <div className="col-8 ms-5">
                     <div className="row">
                         <div className="ms-5 col-12 d-flex justify-content-between">
                             <h1 className="h2 ms-5" >Loading product information ....</h1> 
                                
                            </div>
                        </div>
                    </div>
                </div>)
                
                :
                (<div className="row mt-3 d-flex justify-content-center">
                <div className="col-8 ms-4">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between">
                            <h1 className="h2">Update Product Information</h1> 
                            
                        </div>
                        <div className="col-12 mb-2 d-flex justify-content-center">
                            <img src={
                                productImage
                            } alt="" className="w-25"  />
                        </div>
                        <div className="col-12">
                            <div className="col-12 mb-2 d-flex justify-content-center">
                            <label htmlFor="product_image" ref={element} className="btn btn-primary">Upload Product Image</label>
                        </div> 
                        <div className="col-12">
                            <input type="file"
                            className="invisible"
                            id="product_image"
                            onChange={(e) => { uploadImage(e.target.files)}}
                            />
                            </div> 
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-2">
                            <label htmlFor="product_name" className="form-label">Product Name</label>
                            <input type="text"
                            className="form-control"
                            id="product_name"
                            placeholder="Dress"
                            value = {productName}
                            onChange={(e)=>
                                setProductName(e.target.value)
                            }
                            />
                        </div>
                            {/* div */}
                        <div className="col-12"> </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-2">
                        <label htmlFor="category" className="form-label">Category</label>
                        <select
                        name="catagory"
                        id="category"
                        className="form-control"
                        onChange={(e)=>setProductCategory(e.target.value)}
                        >
                            {
                                productCategory=="women" ? 
                                <>
                                    <option value="women" selected="selected">Women</option>
                                    <option value="men">Men</option>
                                    <option value="kid">Kid</option> 
                                </>
                                   :
                                   productCategory=="men" ?
                                   <>
                                    <option value="women" >Women</option>
                                    <option value="men" selected="selected">Men</option>
                                    <option value="kid">Kid</option> 
                                   </>  
                                   :
                                   <>
                                    <option value="women" >Women</option>
                                    <option value="men">Men</option>
                                    <option value="kid" selected="selected">Kid</option> 
                                   </>
                               
                            }
                       
                        </select>
                    </div> 
                     {/* div */}
                     <div className="col-12"></div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-2">
                        <label htmlFor="description" className="form-label">Description</label>
                        <input type="text"
                        className="form-control"
                        id="description"
                        placeholder="This a dress."
                        value = {productDescription}
                        onChange={(e)=>
                            setProductDescription(e.target.value)
                        }
                        />
                    </div>
                    {/* div */}
                    <div className="col-12"></div>         
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-2">
                        <label htmlFor="price" className="form-label">Price</label>
                        <input type="number"
                        step=".01"
                        className="form-control"
                        id="price"
                        placeholder="10.99"
                        value = {productPrice}
                        onChange={(e)=>
                            setProductPrice(e.target.value)
                        }
                        />
                    </div>  
                     {/* div */}
                    {/* <div className="col-12"></div>  
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-2">
                    <label htmlFor="floor" className="form-label">Floor</label>
                    <input type="number"
                      className="form-control"
                      id="floor"
                      placeholder="10"
                      value={quantityFloor}
                      onChange={(e) => { 
                        setQuantityFloor(e.target.value)
                      }}
                    />
                  </div>  */}
                    <div className="col-12"></div>  
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-2 d-flex justify-content-end"> 
                    <button type="button" className="mt-2 btn btn-primary" ref={btnUpdate} onClick={updateProduct}>Update Product</button>   
                    </div>     
                  
                        </div>
                    </div>
                    </div>
                   
            </div> ) 
            }
            
        </>
      
    );
}

export default AdminUpdateProduct;