import React, { useState,useEffect} from "react";
import Header from "../Header/Header";
import Title from "../Title/Title";
import { useNavigate } from "react-router-dom";
import Subscription from "../Subscription/Subscription";
import Footer from "../Footer/Footer";
import GoCartComponent from "../GoCartComponent/GoCartComponent";
import { getProduct } from '../Helper/ProductList';
import { useDispatch, useSelector } from 'react-redux';
import { setProducts,searchWord,setCart,setOrder } from '../../redux/actions/productActions';
import http from '../../http';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OrderList from "../OrderList/OrderList";
function GoCart(props) {
  const navigate = useNavigate();
  const newSearchWord = useSelector((state) => state.searchWord);
  const newCart = useSelector((state) => state.allCart.products);
  const allOrder = useSelector((state) => state.orderProducts.products);

  const dispatch = useDispatch();
  const fetchProduct = async () => {
    if (localStorage.getItem('user_id') != undefined) { 
      const user_id = localStorage.getItem('user_id');
      http.get(`/cart/${user_id}`).then(res => {
            if (res.data[0].status=='1') { 
                dispatch(setCart(res.data[1]));
            }
    }).catch(error => console.log('error', error));
    }
    
  }
  
  const fetchOrder = async () => {
    if (localStorage.getItem('user_id') != undefined) { 
      const user_id = localStorage.getItem('user_id');
      http.get(`/order/${user_id}`).then(res => {
            if (res.data[0].status=='1') { 
                dispatch(setOrder(res.data[1]));
            }
    }).catch(error => console.log('error', error));
    }
    
}
useEffect(() => { 
  if (localStorage.getItem('user_id') == undefined) { 
    navigate('/');
  }
    fetchProduct();
}, [])


  useEffect(() => {
    const initHome = {
      'page': 'CART',
      'search':''
    }
    dispatch(searchWord(initHome));
    fetchOrder();
  }, []);
  return (
    <>
      <Header />
      <GoCartComponent />
      <OrderList/>
      <Subscription />
      <Footer />
      <ToastContainer/>
    </>
  );
}

export default GoCart;
