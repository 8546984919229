
import React from 'react';
import AdminHeader from '../AdminHeader/AdminHeader';
import AdminProductPage from '../AdminProductPage/AdminProductPage';
import AdminUpdateProduct from '../AdminUpdateProduct/AdminUpdateProduct';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebars from '../Sidebars/Sidebars';
function AdminUpdateProductPage() {
    return (
        <>
             <AdminHeader/>
            <div className="container-fluid">
                <div className="row">
                <Sidebars/>
                <main className="col-md-9 col-lg-10 px-md-4">
                    <AdminUpdateProduct/>
                </main>
                 </div>
            </div>
             <ToastContainer/>
        </>
    );
}

export default AdminUpdateProductPage;