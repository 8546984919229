
import React from 'react';
import { Link } from 'react-router-dom';
function Sidebars() {
    return (
       <>
        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                <div className="position-sticky pt-3 sidebar-sticky">
                    <ul className="nav flex-column">
                    <li className="nav-item">
                        <Link className="nav-link active text-dark" aria-current="page" to="/admin-dashboard">
                        <i className="fa-solid fa-gauge me-3"></i>
                        Dashboard
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-dark" to="/admin-order">
                        <i className="fa-solid fa-cart-shopping me-3"></i>
                        Orders
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-dark" to="/admin-product">
                        <i className="fa-solid fa-cube me-3"></i>
                        Products
                        </Link>
                    </li>
                    </ul>
                </div>
                    </nav>
  </>   
    );
}

export default Sidebars;