
import React from 'react';
import ProductCard from '../ProductCardKid/ProductCard';
import ProductCategory from '../ProductCategory/ProductCategory';
import { useSelector } from 'react-redux';
function ProductContent() {
  const newCart = useSelector((state) => state.searchProductsKid.products);
    return (
        <>
         <section>
        <div className="container-fluid">
          <div className="row">
          
            <div className="col-xxl-12 col-xl-12 col-lg-12">
            
              <div className="row" id="dress-list">
              {newCart.length <= 0 ?
                      <div
                      id="spin"
                      className="spinner-border text-primary mx-auto"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  :
                  <ProductCard/>
                }
                 
              </div>
          
            </div>
          </div>
        </div>
      </section>
        </>
    );
}

export default ProductContent;