
import React from 'react';
import ProductCard from '../ProductCard/ProductCard';
import ProductCategory from '../ProductCategory/ProductCategory';

function ProductContent() {
    return (
        <>
         <section>
        <div className="container-fluid">
          <div className="row">
          
            <div className="col-xxl-12 col-xl-12 col-lg-12">
            
              <div className="row" id="dress-list">
               <ProductCard/>   
              </div>
          
            </div>
          </div>
        </div>
      </section>
        </>
    );
}

export default ProductContent;