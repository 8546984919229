import React from 'react';

function Title(props) {
    return (
        <>
      <section className="mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-5 mt-xs-5">
        <div className="container">
          <div className="row">
            <div
              className="women-header mt-5 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
            >
                <span id="homeLabel"></span>
            </div>
          </div>
        </div>
      </section>
        </>
    );
}

export default Title;