import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../HeaderKid/Header";
import ProductContent from "../ProductContentKid/ProductContent";
import Subscription from "../Subscription/Subscription";
import Title from "../Title/Title";
import { getProduct } from "../Helper/ProductList";
function KidPage() {
  
  return (
    <>
      <Header />
      <Title />

      <ProductContent />

      <Subscription />
      <Footer />
    </>
  );
}

export default KidPage;
