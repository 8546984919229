
import React, { useEffect} from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Register from '../Register/Register';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { setProducts, searchWord,setSearchProducts } from '../../redux/actions/productActions';
function RegisterPage(props) {
 
   
   
    return (
        <>
            <Header />
            <Register/>
            <Footer />
            <ToastContainer/>
        </>
    );
}

export default RegisterPage;