
import React, { useState,useEffect} from 'react';
import { Link,useParams } from "react-router-dom";
import http from '../../http';
import './Register.css';

import {
    regions,
    provinces,
    cities,
    barangays,
} from "select-philippines-address";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Register() {
    const [isAdding, setIsAdding] = useState(0);
    const [regionss, setRegionss] = useState([]);
    const [provincess, setProvincess] = useState([]);
    const [citiess, setCitiess] = useState([]);
    const [barangayss, setBarangayss] = useState([]);

    const [getregionss, setGetRegionss] = useState();
    const [getprovincess, setGetProvincess] = useState();
    const [getcitiss, setGetCitiss] = useState();
    const [getBarangay, setGetBarangay] = useState();
    const [getEmail, setGetEmail] = useState('');
    const [getPassword, setGetPassword] = useState('');
    const [getFName, setGetFName] = useState('');
    const [getMName, setGetMName] = useState('');
    const [getLName, setGetLName] = useState('');
    const [getMobileNumber, setGetMobileNumber] = useState('');
    const [getAddress, setGetAddress] = useState('');

    const [provinceName, setProvinceName] = useState('');
    const [cityName, setCityName] = useState('');
    const [barangayName, setBarangayName] = useState('');

    const addRegister = () => {
        setIsAdding(1);
        const newEmail = getEmail;
        const newPassword = getPassword;
        const newFName = getFName;
        const newMName = getMName;
        const newLName = getLName;
        const newMobile = getMobileNumber;
        const newAddress = getAddress;
        const newBarangay = getBarangay;
        const newCity = cityName;
        const newProvince = provinceName;
        if ((newMName.length>=1)&&(newMName.length<=2)) {
            if ((newEmail.length > 0) && (newPassword.length > 0) && (newFName.length > 0) && (newLName.length > 0)
            &&(newMobile.length>0) &&(newAddress.length>0) &&(newBarangay.length>0)&&(newCity.length>0)&&(newProvince.length>0)) { 
               
                const newCustomer = {
                    email:newEmail,
                    password:newPassword,
                    first_name:newFName,
                    middle_initial:newMName,
                    last_name:newLName,
                    mobile_number:newMobile,
                    address_line_1:newAddress,
                    barangay:newBarangay,
                    city:newCity,
                    province:newProvince
                }
                http.post('/customers', newCustomer).then((result) => {
                      if (result.data.status=='1') {
                          notifySuccess(result.data.message);
                          setIsAdding(0);
                      } else {
                          notifyError(result.data.message); 
                          setIsAdding(0);  
                      }
                })
            } else {
                notifyError("Please fill-out all the fields.");
                setIsAdding(0);  
            }
        } else {
            notifyError("Middle initial must be 1 to 2 letters only");
            setIsAdding(0);  
        }
        
        
        
        
    }
    const notifySuccess = (result) => { 
        toast.success(`${result}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
    }
    const notifyError=(result)=>{
       toast.error(`${result}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
    }
    useEffect(() => {
        regions().then((region) => setRegionss(region));
    }, [regionss]);
    
    useEffect(() => {
        provinces(getregionss).then((province) => setProvincess(province));
        
    }, [getregionss]);
    
    useEffect(() => {
        cities(getprovincess).then((city) => setCitiess(city));
        console.log(provincess);
    }, [getprovincess]);
    useEffect(() => {
        barangays(getcitiss).then((barangay) => setBarangayss(barangay));

        const e_province = getprovincess;
        const e_city = getcitiss;

        const province_obj = provincess.filter((province)=>province.province_code==e_province)
        const city_obj = citiess.filter((city)=>city.city_code==e_city)
        if (province_obj[0]!==undefined) { 
            setProvinceName(province_obj[0].province_name); 
            console.log(province_obj[0].province_name);
        }
        if (city_obj[0]!==undefined) { 
            setCityName(city_obj[0].city_name);
            console.log(city_obj[0].city_name);
        }
        
        
    }, [getcitiss]);
    
    useEffect(() => {
        
    }, [cityName]);
    useEffect(() => {
        
    },[isAdding]);
    return (
        <>
            <section className="mt-4">
        <div className="container">
          <div className="row">
            <div className="subs col-xxl-12 col-xl-12 col-lg-12">
              <h3>Register</h3>

              <div className="new-form d-flex flex-column justify-content-between mt-4">
                <div className="input-container mt-2">
                <i className="fa-solid fa-envelope text-white mt-1 fs-5"></i>
                  <input
                    type="email"
                    id="loginEmail"
                    placeholder="Enter your mail"
                    value={getEmail}
                    onChange={(e)=>setGetEmail(e.target.value)}
                  />
                </div>
                <span className="text-white text-center"></span>
                <div className="input-container mt-2">
                <i className="fa-solid fa-lock text-white mt-1 fs-5"></i>
                  <input
                    type="password"
                    id="loginPassword"
                    placeholder="Enter your password"
                    value={getPassword}
                    onChange={(e)=>setGetPassword(e.target.value)}
                  />
                </div>
                <span className="text-white text-center"></span>
                <div className="input-container mt-2">
                <i className="fa-solid fa-user text-white mt-1 fs-5"></i>
                  <input
                    type="text"
                    id="fname"
                    placeholder="Enter your first name"
                    value={getFName}
                    onChange={(e)=>setGetFName(e.target.value)}
                  />
                </div>
                <span className="text-white text-center"></span>
                <div className="input-container mt-2">
                <i className="fa-solid fa-user text-white mt-1 fs-5"></i>
                  <input
                    type="text"
                    id="middleinitial"
                    placeholder="Enter your middle initial"
                    value={getMName}
                    onChange={(e)=>setGetMName(e.target.value)}
                  />
                </div>
                <div className="input-container mt-2">
                <i className="fa-solid fa-user text-white mt-1 fs-5"></i>
                  <input
                    type="text"
                    id="lname"
                    placeholder="Enter your last name"
                    value={getLName}
                    onChange={(e)=>setGetLName(e.target.value)}
                  />
                </div>
                <div className="input-container mt-2">
                <i className="fa-solid fa-mobile text-white mt-1 fs-5"></i>
                  <input
                    type="text"
                    id="mobile"
                    placeholder="Enter your mobile number"
                    value={getMobileNumber}
                    onChange={(e)=>setGetMobileNumber(e.target.value)}
                  />
                </div>
                <div className="input-container mt-2">
                <i className="fa-solid fa-location-dot text-white mt-1 fs-5"></i>
                  <input
                    type="text"
                    id="address"
                    placeholder="Enter your house number/street/zone"
                    value={getAddress}
                    onChange={(e)=>setGetAddress(e.target.value)}
                  />
                </div>
                 <div className="input-container mt-2">
                 <i className="fa-solid fa-location-dot text-white mt-1 fs-5"></i>
                  <select name="region" 
                    className="form-control select-class" 
                    onChange={(e) => setGetRegionss(e.target.value)}>
                    {   
                        regionss.map((region)=> <option key={region.id} value={region.region_code}>{region.region_name }</option>
                        )
                    }
                  </select>
                </div>
                <span className="text-white text-center"></span>
                <div className="input-container mt-2">
                <i className="fa-solid fa-location-dot text-white mt-1 fs-5"></i>
                  <select name="province" 
                    className="form-control select-class" 
                    onChange={(e) => setGetProvincess(e.target.value)}>
                    {   
                        provincess.map((province,index)=> <option key={index} value={province.province_code}>{province.province_name }</option>
                        )
                    }
                  </select>
                </div>
                <span className="text-white text-center"></span>
                <div className="input-container mt-2">
                <i className="fa-solid fa-location-dot text-white mt-1 fs-5"></i>
                  <select name="city" 
                    className="form-control select-class" 
                    onChange={(e) => setGetCitiss(e.target.value)}>
                    {   
                        citiess.map((city,index)=> <option key={index} value={city.city_code}>{city.city_name }</option>
                        )
                    }
                  </select>
                </div>
                <span className="text-white text-center"></span>
                <div className="input-container mt-2">
                <i className="fa-solid fa-location-dot text-white mt-1 fs-5"></i>
                  <select name="barangay" 
                    className="form-control select-class" 
                    onChange={(e) => setGetBarangay(e.target.value)}>
                    {   
                        barangayss.map((barangay,index)=> <option key={index} value={barangay.brgy_name}>{barangay.brgy_name }</option>
                        )
                    }
                  </select>
                </div>
                <span className="text-white text-center"></span>
                {
                    isAdding===0 ?
                    <button
                        id="loginBtn"
                        className="btn btn-warning bg-primary ms-2 mt-3"
                        onClick={()=>addRegister()}
                    >
                        Register
                  </button>
                  :
                  <button
                  id="loginBtn"
                  className="btn btn-warning bg-primary ms-2 mt-3"
                  disabled
                >
                  <div className="spinner-border text-primary spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                    </div>
                </button>
                }
               
                <span className="mt-2"><Link to="/login" className="text-white">Have an account? Please click me to login.</Link></span>
              </div>
            </div>
          </div>
        </div>
      </section>
        </>
    );
}

export default Register;