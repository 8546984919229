import React, { useState,useEffect} from "react";
import Header from "../Header/Header";
import Title from "../Title/Title";
import Subscription from "../Subscription/Subscription";
import Footer from "../Footer/Footer";
import ProductInfo from "../ProductInfo/ProductInfo";
import { getProduct } from '../Helper/ProductList';
function SingleProduct(props) {
  const products = [...getProduct()];
  const [searchs,setSearches] = useState('All');
  const searchProduct = (search) => { 
    setSearches(search);
  }

  useEffect(() => {
    setSearches('All');
  },[]);
  return (
    <>
      <Header searchProduct={ searchProduct}/>
      <Title />
      {/* <ProductInfo /> */}
      <Subscription />
      <Footer />
    </>
  );
}

export default SingleProduct;
