
import React, {useEffect} from 'react';
import { Link,Navigate,useNavigate  } from "react-router-dom";
function AdminHeader() {
    const navigate = useNavigate();
    const checkAdmin = () => {
        if (localStorage.getItem('privelege') != "admin") { 
            navigate("/");
        }
    }
    useEffect(() => {
        checkAdmin();
    });
    return (
        <>
            <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
            <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href="/admin-dashboard">Aling Nena</a>
            <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            
            <div className="navbar-nav">
                <div className="nav-item text-nowrap">
                <Link className="nav-link px-3" to="/logout">Sign out</Link>
                </div>
            </div>
            </header>
        </>
    );
}

export default AdminHeader;