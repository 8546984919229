import { combineReducers } from 'redux';
import { productReducer, selectedProductReducer,filteredProductsReducer, searchWordReducer, searchProductReducer, cartProductReducer, orderProductReducer, orderCartProductReducer, orderPendingProductReducer, orderCompleteProductReducer, searchProductWomenReducer, searchProductMenReducer, searchProductKidReducer } from './productReducer';

const reducers = combineReducers({
    allProducts:productReducer,
    allCart:cartProductReducer,
    orderProducts:orderProductReducer,
    orderAllProducts:orderCartProductReducer,
    orderAllPendingProducts:orderPendingProductReducer,
    orderAllCompleteProducts:orderCompleteProductReducer,
    product:selectedProductReducer,
    searchWord:searchWordReducer,
    searchProducts:searchProductReducer,
    searchProductsWomen:searchProductWomenReducer,
    searchProductsMen:searchProductMenReducer,
    searchProductsKid:searchProductKidReducer,
  
});

export default reducers;