import React, { useState,useEffect} from "react";
import AnsLoginComponent from "../AnsLoginComponent/AnsLoginComponent";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { getProduct } from '../Helper/ProductList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function AnsLogin(props) {
 
  return (
    <>
      <Header />
      <AnsLoginComponent />
      <Footer />
      <ToastContainer/>
    </>
  );
}

export default AnsLogin;
