
import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminHeader from '../AdminHeader/AdminHeader';
import AdminProduct from '../AdminProduct/AdminProduct';
import Sidebars from '../Sidebars/Sidebars';
import { setProducts } from '../../redux/actions/productActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../http';
function AdminProductPage() {
  
    const dispatch = useDispatch();
  
    const products = useSelector((state) => state.allProducts.products);
    const fetchProduct = async () => {
        http.get('/products').then(res => {
            console.log(res.data[0]);
                if (res.data[0].status=='1') { 
                    dispatch(setProducts(res.data[1]));
                }
        }).catch(error => console.log('error', error));
    }
    useEffect(() => { 
        fetchProduct();
    }, [])
   
    return (
        <>
            <AdminHeader/>
            <div className="container-fluid">
                <div className="row">
                <Sidebars/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <AdminProduct/>
                </main>
                 </div>
            </div>
            <ToastContainer/>
        </>
    );
}

export default AdminProductPage;