
import React,{useEffect, useState,useRef} from 'react';
import './OrderList.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../http';
function OrderList(props) {
    const prod = useRef(null);
    const [orders, setOrders] = useState([]);
    const [productList, setProductList] = useState([]);
    const allOrder = useSelector((state) => state.orderProducts.products);
    const dispatch = useDispatch();
  
    // const displayTable = allOrder.map((order,index) => {
    //     return (
    //         <tr key={index}>
    //         <td>{order.id}</td>
    //         <td>{order.date_order}</td>
    //         <td>{order.status.toUpperCase()}</td>
    //         <td>
    //                 <Link to={`/cart/${order.id}`} className="btn btn-warning">
    //                <i className="fa-solid fa-eye"></i>     
    //             </Link>
    //         </td>
    //     </tr>  
    //     );
    // });

    // useEffect(() => {
    //     setOrders(allOrder);
    // },[orders,allOrder]);
    return (
        <>
<div className="container mt-4">
<div className="row header header-table" >

<h3>Order History</h3>
                </div>
<table className="table  tableName mt-3">
        <thead>
            <tr>
                <th>Order id</th>
                <th>Order Date</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
           {
            allOrder.length > 0 ?
            allOrder.map((order,index) => {
                return (
                    <tr key={index}>
                    <td>{order.id}</td>
                    <td>{order.date_order}</td>
                    <td>{order.status.toUpperCase()}</td>
                    <td>
                            <Link to={`/cart/${order.id}`} className="btn btn-warning">
                           <i className="fa-solid fa-eye"></i>     
                        </Link>
                    </td>
                </tr>  
                );
            })
            :
            <tr>
                <td colSpan="4" className="text-center">
                <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
                </div>  
                <span className="ms-2">Preparing your order history ...</span>
                </td>
            </tr>
           }
           
        </tbody>
       
    </table>
    </div> 
        </>
    );
}

export default OrderList;