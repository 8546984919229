
import React, { useState,useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProducts } from '../../redux/actions/productActions';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../http';
function ProductModal() {
  let imageInitialLink = "https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg";
  
  const products = useSelector((state) => state.allProducts.products);
  const [isUploading, setIsUploading] = useState(0);
  const [isAdding, setIsAdding] = useState(0);
  const [productName, setProductName] = useState('');
  const [quantityFloor, setQuantityFloor] = useState(0);
  const [productDescription, setProductDescription] = useState('');
  const [productCategory, setProductCategory] = useState('women');
  const [productPrice, setProductPrice] = useState(0.00);
  const [productImage, setProductImage] = useState(imageInitialLink);
  const dispatch = useDispatch();
  const api = 'http://localhost/my-app-be-test/my-app-be/public/';

  
  const addProduct = () => {
  
    setIsAdding(1);
    const addedProduct = {
      product_name: productName,
      category_name:productCategory,
      description: productDescription,
      image:productImage,
      price: productPrice,
      quantity_floor:0
    }
    http.post('/products', addedProduct).then((result) => {
      const newProduct = [...products];
          newProduct.push(addedProduct);
        dispatch(setProducts(newProduct));
     
          if (result.data.status=='1') {
            notifySuccess(result.data.message);
            
          } else {
            notifyError(result.data.message);  
          }
          setIsAdding(0);
    })

  }
  const notifySuccess = (result) => { 
      toast.success(`${result}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  }
  const notifyError=(result)=>{
     toast.error(`${result}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  }
  useEffect(() => {
    const btnAdd = document.querySelector('.btnAdd');
    if (isAdding==1) {
      btnAdd.classList.add('disabled');
      btnAdd.innerText="Saving product...";
    } else {
      btnAdd.classList.remove('disabled');
      btnAdd.innerText = "Add Product";
    }
  }, [isAdding]);
  useEffect(() => {
    const btnUpload = document.querySelector('.uploadButton');
    if (isUploading==1) {
      btnUpload.classList.add('disabled');
      btnUpload.innerText="Uploading product image....";
    } else {
      btnUpload.classList.remove('disabled');
      btnUpload.innerText = "Upload Product Image";
    }
  }, [isUploading]);

  useEffect(() => {
    const btnAdd = document.querySelector('.btnAdd');
    if ((productName=="")||(productDescription=="")||(productPrice==0.00)||(productImage==imageInitialLink)) {
      btnAdd.classList.add('disabled');
    } else {
      btnAdd.classList.remove('disabled');
      
    }
  }, [productName,productDescription,productCategory,productPrice,productImage,productImage]);
  

  const uploadImage = (files) => {
    setIsUploading(1);
    const formData = new FormData();
    formData.append("file",files[0]);
    formData.append("upload_preset","wmnayrn3");
    Axios.post("https://api.cloudinary.com/v1_1/codestudio28/image/upload", formData)
      .then((res) => { 
        setProductImage(res.data.url);
        setIsUploading(0);
      }).catch((err) => { 
        notifyError(err.message);
        setIsUploading(0);
      });
  }
 
    return (
        <>
         <a href="#createModal" className="btn btn-primary pt-2" data-bs-toggle="modal">
            <i className="fa-solid fa-plus me-2"></i>
            Product
            </a>        
<div className="modal fade" id="createModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Add Product</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         
      </div>
      <div className="modal-body">
                <div className="row">
                  <div className="col-12 mb-2 d-flex justify-content-center">
                    <img src={productImage} alt="" className="w-25"  />
                  </div>
                  <div className="col-12 mb-2 d-flex justify-content-center">
                    <label htmlFor="product_image" className="btn btn-primary uploadButton">Upload Product Image</label>
                  </div> 
                  <div className="col-12">
                    <input type="file"
                      className="invisible"
                      id="product_image"
                      onChange={(e) => { uploadImage(e.target.files)}}
                    />
                  </div>
                  <div className="col-12 mb-2">
                    <label htmlFor="product_name" className="form-label">Product Name</label>
                    <input type="text"
                      className="form-control"
                      id="product_name"
                      placeholder="Dress"
                      onChange={(e) => { 
                        setProductName(e.target.value)
                      }}
                    />
                  </div> 
                  <div className="col-12 mb-2">
                    <label htmlFor="category" className="form-label">Category</label>
                    <select
                      name="category"
                      id="category"
                      className="form-control"
                      onChange={(e) => { 
                     
                        setProductCategory(e.target.value)
                      }}
                    >
                      <option value="women">Women</option>
                      <option value="men">Men</option>
                      <option value="kid">Kid</option>
                    </select>
                  </div> 
                  <div className="col-12 mb-2">
                    <label htmlFor="description" className="form-label">Description</label>
                    <input type="text"
                      className="form-control"
                      id="description"
                      placeholder="This a dress."
                      onChange={(e) => { 
                        setProductDescription(e.target.value)
                      }}
                    />
                  </div> 
                  <div className="col-12 mb-2">
                    <label htmlFor="price" className="form-label">Price</label>
                    <input type="number"
                      step=".01"
                      className="form-control"
                      id="price"
                      placeholder="10.99"
                      onChange={(e) => { 
                        setProductPrice(e.target.value)
                      }}
                    />
                  </div> 
                  {/* <div className="col-12 mb-2">
                    <label htmlFor="prfloorice" className="form-label">Floor</label>
                    <input type="number"
                      className="form-control"
                      id="floor"
                      placeholder="10"
                      onChange={(e) => { 
                        setQuantityFloor(e.target.value)
                      }}
                    />
                  </div>  */}
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary btnAdd" onClick={addProduct }>Add Product</button>
      </div>
    </div>
  </div>
        </div>
     
        </>

    );
}

export default ProductModal;