
import React, { useEffect } from 'react';
import AdminHeader from '../AdminHeader/AdminHeader';
import AdminOrder from '../AdminOrder/AdminOrder';
import Sidebars from '../Sidebars/Sidebars';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderPendingProduct,setOrderCompleteProduct } from '../../redux/actions/productActions';
import http from '../../http';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdminOrderPage() {
    const allOrder = useSelector((state) => state.orderAllPendingProducts.products);
    const allComplete = useSelector((state) => state.orderAllCompleteProducts.products);
    console.log(allComplete);
    const dispatch = useDispatch();
    const fetchProduct = async () => {
          http.get(`/order-pending`).then(res => {
                if (res.data[0].status=='1') { 
                    dispatch(setOrderPendingProduct(res.data[1]));
                    dispatch(setOrderCompleteProduct(res.data[2]));
                }
        }).catch(error => console.log('error', error));
        
        
      }
    
    useEffect(() => {
        fetchProduct();
    },[]);
    
    return (
        <>
            <AdminHeader/>
            <div className="container-fluid">
            <div className="row">
            <Sidebars/>
               
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                       <AdminOrder/>
                </main>
                 </div>
            </div>
            <ToastContainer/>
        </>
    );
}

export default AdminOrderPage;