import React,{useEffect} from 'react';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../http';
import { setOrderProduct } from '../../redux/actions/productActions';

function OrderReport(props) {
    const { orderId } = useParams();
    const products = useSelector((state) => state.orderAllPendingProducts.products);
    const allOrderProduct = useSelector((state) => state.orderAllProducts.products);
    const dispatch = useDispatch();
    const fetchProduct = async () => {
     
          http.get(`/order-list/${orderId}`).then(res => {
                if (res.data[0].status=='1') { 
                    dispatch(setOrderProduct(res.data[1]));
                    const newCustomer = products.filter((product)=>product.id==orderId);
                    
                    console.log(res.data[1]);
                    loadPDF(res.data[1],newCustomer[0]);
                }
        }).catch(error => console.log('error', error));
        
        
    }
    
    const loadPDF = (data,customer) => {
        
        const record = [];
        let total = 0;

        console.log("Before map",data[0]);
        data.map(((product, index) => {
            const col = [];
            col.push(product.product_name);
            col.push(product.quantity);
            col.push(`PHP ${product.price.toLocaleString("en-US", product.price)}`);
            col.push(`PHP ${product.sub_price.toLocaleString("en-US", product.sub_price)}`);
            total += parseFloat(product.sub_price);
            record.push(col);
          

        }))
        const col = [];
        col.push("");
        col.push("");
        col.push("Total : ");
        col.push(`PHP ${total.toLocaleString("en-US", total)}` );
        record.push(col);
        console.log(record);
        const doc = new jsPDF();
        doc.text(`Order Reciept#${orderId}`, 20, 10);
        // doc.text(`Customer Name: ${customer.first_name} ${customer.middle_initial} ${customer.last_name}`, 20, 20);
        // doc.text(`Address: ${customer.address_line_1} ${customer.barangay} ${customer.city} ${customer.province}`, 20, 30);

        autoTable(doc, {
            head: [['Product Name', 'Quantity','Price','Sub Total']],
            body: record,
          },0,50)
       
        doc.save(`order_reciept#${orderId}.pdf`);
    }

    useEffect(() => {
        fetchProduct();
    },[]);
    return (
        <div>
           
        </div>
    );
}

export default OrderReport;