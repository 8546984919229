import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import Home from "./components/Home/Home";
import Products from "./components/Products/Products";
import SingleProduct from "./components/SingleProduct/SingleProduct";
import GoCart from "./components/GoCart/Gocart";
import AnsLogin from "./components/AnsLogin/AnsLogin";
import Header from "./components/Header/Header";
import KidPage from "./components/KidPage/KidPage";
import MenPage from "./components/MenPage/MenPage";
import WomenPage from "./components/WomenPage/WomenPage";
import DashboardPage from "./components/DashboardPage/DashboardPage";
import AdminProductPage from "./components/AdminProductPage/AdminProductPage";
import AdminOrderPage from "./components/AdminOrderPage/AdminOrderPage";
import AdminUpdateProductPage from "./components/AdminUpdateProductPage/AdminUpdateProductPage";
import RegisterPage from "./components/RegisterPage/RegisterPage";
import Logout from "./components/Logout/Logout";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import CustomerProduct from "./components/CustomerProduct/CustomerProduct";
import OrderReport from "./components/OrderReport/OrderReport";
function App() {
	const initialOptions = {
		"client-id":
			"AR0sGmS1-2ocG5h2A-SV8B9_IJH2E0F7A1vFjuOOowWNtm9RAOypXEyMed-V4KgdZKaFKTM0fBv-F8Rg",
		currency: "PHP",
	};
	return (
		<>
			<PayPalScriptProvider options={initialOptions}>
				<BrowserRouter basename="/">
					{/* <BrowserRouter> */}
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="product-list" element={<Products />} />
						<Route path="product" element={<SingleProduct />} />
						<Route path="kid" element={<KidPage />} />
						<Route path="men" element={<MenPage />} />
						<Route path="women" element={<WomenPage />} />
						<Route path="cart" element={<GoCart />} />
						<Route path="cart/:orderId" element={<CustomerProduct />} />
						<Route path="login" element={<AnsLogin />} />
						<Route path="admin-dashboard" element={<DashboardPage />} />
						<Route path="admin-order" element={<AdminOrderPage />} />
						<Route path="admin-product" element={<AdminProductPage />} />
						<Route
							path="admin-product/:productId"
							element={<AdminUpdateProductPage />}
						/>
						<Route path="order-pdf/:orderId" element={<OrderReport />} />
						<Route path="register" element={<RegisterPage />} />
						<Route path="logout" element={<Logout />} />
					</Routes>
				</BrowserRouter>
			</PayPalScriptProvider>
		</>
	);
}

export default App;
