import React, { useEffect} from 'react';
import AdminHeader from '../AdminHeader/AdminHeader';
import Dashboard from '../Dashboard/Dashboard';
import Sidebars from '../Sidebars/Sidebars';

function DashboardPage() {
   
    return (
        <>
            <AdminHeader/>
            <div className="container-fluid">
                <div className="row">
                <Sidebars/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
                       <Dashboard/>
                    </div>
                </main>
                 </div>
            </div>
        </>
    );
}

export default DashboardPage;