import React,{useEffect,useState} from 'react';
import http from '../../http';




function Dashboard() {
    const [numberCustomer, setNumberCustomer] = useState(0);
    const [numberPending, setNumberPending] = useState(0);
    const [numberComplete, setNumberComplete] = useState(0);
    const [numberEarning, setNumberEarning] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    const fetchDashboard = () => {
        setIsLoading(1);
      
        http.get(`/admin-dashboard`).then((res) => {
            setNumberComplete(res.data.totalComplete);
            setNumberPending(res.data.totalPending);
            setNumberCustomer(res.data.totalCustomer);
            setNumberEarning(res.data.totalEarning);
            setIsLoading(0);
        })
        .catch(error => { 
            setIsLoading(0);
          console.log(error.message);
        });
    
    }
    useEffect(() => {
        fetchDashboard();
    },[]);
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12  border-bottom">
                    <h1 className="h2">Dashboard</h1>
                    </div>
                    <div className="col-12">
                    <div className="row">
                <div className="col-xl-3 col-md-6 mb-4 mt-4">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                Customers</div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                    {
                                                        numberCustomer > 0 && isLoading == 0 ?
                                                            <span>{numberCustomer}</span>
                                                        :
                                                        numberCustomer==0 && isLoading==1 ?
                                                        <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        <span className="visually-hidden">Please wait. We are preparing your cart...</span>
                                                        </>
                                                        :
                                                        0
                                                    }
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-users fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            {/* -- */}
                            <div className="col-xl-3 col-md-6 mb-4 mt-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                Total Earnings</div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                {
                                                        numberEarning > 0 && isLoading == 0 ?
                                                            <span>PHP {
                                                                numberEarning.toLocaleString("en-US", numberEarning)
                                                            }</span>
                                                        :
                                                        numberEarning==0 && isLoading==1 ?
                                                        <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        <span className="visually-hidden">Please wait. We are preparing your cart...</span>
                                                        </>
                                                        :
                                                        <span>PHP. 0.00</span>
                                                    }
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fa-solid fa-money-bill fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            {/* -- */}
                            <div className="col-xl-3 col-md-6 mb-4 mt-4">
                            <div className="card border-left-warning shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                                Completed Orders</div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                {
                                                        numberComplete > 0 && isLoading == 0 ?
                                                            <span>{numberComplete}</span>
                                                        :
                                                        numberComplete==0 && isLoading==1 ?
                                                        <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        <span className="visually-hidden">Please wait. We are preparing your cart...</span>
                                                        </>
                                                        :
                                                        0
                                                    }
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fa-solid fa-cart-shopping fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            {/* -- */}
                            <div className="col-xl-3 col-md-6 mb-4 mt-4">
                            <div className="card border-left-info shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                                               Pending Orders</div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                {
                                                        numberPending > 0 && isLoading == 0 ?
                                                            <span>{numberPending}</span>
                                                        :
                                                        numberPending==0 && isLoading==1 ?
                                                        <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        <span className="visually-hidden">Please wait. We are preparing your cart...</span>
                                                        </>
                                                        :
                                                        0
                                                    }
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fa-solid fa-cube fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            {/* -- */}
                </div>
                    </div>
                </div>
            </div>
          
          
            
        </>
    );
}

export default Dashboard;