import React, { useState,useEffect} from 'react';
import './CustomerProductList.css';
import { setOrderProduct } from '../../redux/actions/productActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
function CustomerProductList() {
    const allOrderProduct = useSelector((state) => state.orderAllProducts.products);
    const newCart = useSelector((state) => state.allCart.products);
    const { orderId } = useParams();
    let total = 0;
   
    
    return (
        <>
    <div className="container mt-5 product-list">
        <div className="row header header-table">
            <h3>Product List</h3>
                </div>
        <div className="row header text-start">
            <h5>Order Number: {orderId}</h5>
        </div>
            <table className="table  tableName mt-3">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Sub-Total</th>
                        </tr>
                    </thead>
            <tbody>
               {
                allOrderProduct.length > 0  ?
                allOrderProduct.map((product, index) => {
                    total += parseFloat(product.sub_price);
                    return (
                        <tr key={index}>
                                <td>{index+1}</td>
                                <td>
                                <img src={product.image} className="imgProd" alt="" />
                                </td>
                                <td>{product.product_name}</td>
                                <th>{product.quantity}</th>
                                <td> PHP. {product.sub_price}</td>
                                
                        </tr>
                    );
                })
                :
                <tr>
                    <td colSpan="5" className="text-center">
                    <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                    <span>Loading...</span>
                    </td>
                </tr>
               }
                <tr>
                            <th></th>
                            <th></th>
                            <th> </th>
                            <th>Total</th>
                            <th> PHP. 
                                {
                                    total.toLocaleString("en-US", total)
                                }
                            </th>
                </tr>
            </tbody>
       
        </table>
    </div> 
        </>
    );
}

export default CustomerProductList;