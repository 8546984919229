import React, { useState} from "react";
import { Link,Navigate,useNavigate  } from "react-router-dom";
import http from '../../http';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AnsLoginComponent.css';
function AnsLoginComponent(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogging, setIsLogging] = useState(0);
  const navigate = useNavigate();
  const submitLogin = () => {
    setIsLogging(1);
    const loginUser = {
      email:email,
      password:password
    }
    http.post('/login', loginUser).then((result) => {
      if (result.data[0].status == '1') {
        
        notifySuccess(result.data[0].message);
        let fullname = `${result.data[1].first_name} ${result.data[1].middle_initial} ${result.data[1].last_name}`;
        localStorage.setItem('fullname', fullname);
        localStorage.setItem('user_id',result.data[1].id );
        localStorage.setItem('privelege', result.data[1].privelege);
        localStorage.setItem('mobile',result.data[1].mobile_number );
        localStorage.setItem('address',result.data[1].address_line_1 );
        localStorage.setItem('barangay',result.data[1].barangay );
        localStorage.setItem('city',result.data[1].city );
        localStorage.setItem('province', result.data[1].province);
        setIsLogging(0);
        if (result.data[1].privelege == "admin") {
          navigate('/admin-dashboard');    
        } else {
          navigate('/'); 
        }
      } else if (result.data.status == '2') {
        setIsLogging(0);
            notifySuccess(result.data[0].message);
            console.log(result.data);
          } else {
            notifyError(result.data[0].message);  
          }
    })
  }
  const notifySuccess = (result) => { 
    toast.success(`${result}`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
}
const notifyError=(result)=>{
   toast.error(`${result}`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
}
  return (
    <>
      <section className="mt-4 login-container">
        <div className="container">
          <div className="row">
            <div className="subs col-xxl-12 col-xl-12 col-lg-12">
              <h3>Login</h3>

              <div className="new-form d-flex flex-column justify-content-between mt-4">
                <div className="input-container mt-2">
                  <iconify-icon
                    width="1.5em"
                    className="text-white"
                    icon="material-symbols:mail"
                  ></iconify-icon>
                  <input
                    type="email"
                    id="loginEmail"
                    placeholder="Enter your mail"
                    onChange={(e)=> setEmail(e.target.value)}
                  />
                </div>
                <span className="text-white text-center"></span>
                <div className="input-container mt-2">
                  <iconify-icon
                    width="1.5em"
                    className="text-white"
                    icon="material-symbols:lock"
                  ></iconify-icon>
                  <input
                    type="password"
                    id="loginPassword"
                    placeholder="Enter your password"
                    onChange={(e)=> setPassword(e.target.value)}
                  />
                </div>
                <span className="text-white text-center"></span>
                {
                  isLogging === 0 ?
                    <button
                      id="loginBtn"
                      className="btn btn-primary bg-primary ms-2 mt-3"
                      onClick={() => submitLogin()}
                    >
                      Login
                    </button>
                    :
                    <button className="btn btn-primary ms-2 mt-3" type="button" disabled>
                    <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                    Logging in...
                  </button>
                }
                <span className="mt-2"><Link to="/register" className="text-white">No account? Please click me to register.</Link></span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AnsLoginComponent;
